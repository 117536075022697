/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable chai-friendly/no-unused-expressions */
import classNames from 'classnames';
import IconRecurrence from 'components/Icons/IconRecurrence';
import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import { selectedCalendarLinkIdAtom } from 'hooks/calendarLink/creation/useSelectedCalendarLink';
import {
  selectedSlotIdAtom,
  useSelectedSlotId,
} from 'hooks/calendarLink/creation/useSelectedSlotId';
import { useUpdateCalendarSlot } from 'hooks/calendarLink/creation/useUpdateSlot';
import { CalendarMode, calendarModeAtom } from 'hooks/useCalendarMode';
import { useAtomCallback } from 'jotai/utils';
import React, { useCallback } from 'react';
import { getTimeFormat } from 'utils/format';
import { isSlotRecurring } from 'utils/share';
import GridResizeHandles from './GridResizeHandles';
import { formatTime } from './utils';

interface Props {
  slot: CalendarSlotDate;
  timezone: string;
  style?: React.CSSProperties;
  ui24HourClock: boolean;
  disabled?: boolean;
  calendarMode: CalendarMode;
}

// eslint-disable-next-line react/display-name
export default React.memo(
  React.forwardRef(function GridSlotComponent(
    {
      slot,
      timezone,
      style,
      ui24HourClock,
      disabled,
      calendarMode,
      ...props
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) {
    const timeFormatPreference = getTimeFormat(ui24HourClock);
    const selectedId = useSelectedSlotId();
    const selected = selectedId === slot.id;
    const formattedStartAt = formatTime(
      timeFormatPreference,
      slot.startAt.setZone(timezone)
    );
    const formattedEndAt = formatTime(
      timeFormatPreference,
      slot.endAt.setZone(timezone)
    );
    const durationMinutes = slot.endAt.diff(slot.startAt, 'minutes').minutes;
    const isShortEvent = durationMinutes < 25;
    const isRecurring = isSlotRecurring(slot);
    const { updateCalendarSlot } = useUpdateCalendarSlot();

    const handleClick = useAtomCallback(
      useCallback(
        (get, set) => {
          set(selectedSlotIdAtom, slot.id);
          if (get(calendarModeAtom) === 'default') {
            set(selectedCalendarLinkIdAtom, slot.calendarLinkId);
          }
        },
        [slot.id, slot.calendarLinkId]
      )
    );

    return (
      <div
        ref={ref}
        tabIndex={-1}
        onClick={handleClick}
        className={classNames(
          'group bg-background relative flex cursor-pointer',
          {
            'pointer-events-none': disabled,
            'rounded-md': !isShortEvent,
            rounded: isShortEvent,
          }
        )}
        {...props}
        style={style}
        // Important that this role is declared after mergeProps,
        // otherwise dnd library will overwrite the role with `button`
        role="gridcell"
      >
        <div
          className={classNames(
            'border-background flex w-full items-start truncate border pt-0.5 pl-2 transition-colors',
            {
              'rounded-md': !isShortEvent,
              rounded: isShortEvent,
              'bg-gray-300 dark:bg-gray-600 dark:hover:bg-gray-500': !selected,
              'bg-gray-300 dark:bg-gray-500': selected,
              'bg-gray-100 dark:bg-gray-750': disabled,
            }
          )}
        >
          {isRecurring && (
            <div
              className={classNames('flex items-center gap-1 self-start', {
                'mt-1': !isShortEvent,
                '-mt-0.5': isShortEvent,
              })}
            >
              <IconRecurrence className="h-3 w-3 opacity-75" />
              <small
                className={classNames(
                  'min-w-[30px] text-left font-medium leading-none',
                  {
                    'pt-px text-[11px]': !isShortEvent,
                    'text-[9px]': isShortEvent,
                  }
                )}
              >
                {formattedStartAt} - {formattedEndAt}
              </small>
            </div>
          )}
          <GridResizeHandles
            startAt={slot.startAt.setZone(timezone)}
            endAt={slot.endAt.setZone(timezone)}
            onResize={({ startAt, endAt }) =>
              updateCalendarSlot({
                ...slot,
                startAt,
                endAt,
              })
            }
            disabled={isShortEvent || calendarMode === 'default'}
          />
        </div>
      </div>
    );
  })
);
