import { useUserEmail } from 'contexts/auth';
import { useEffect } from 'react';
import { useExtendedFetchEvents } from './events/useGridEvents';

export function FetchCurrentWeekFromCache(): null {
  useFetchCurrentWeekFromCache();
  return null;
}

function useFetchCurrentWeekFromCache(): void {
  const email = useUserEmail();
  const fetchCurrentWeekFromCache = useExtendedFetchEvents({
    requestPolicy: 'cache-first',
  });

  // Run this only on mount
  useEffect(() => {
    if (email) {
      fetchCurrentWeekFromCache(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);
}
