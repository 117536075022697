import { getDayIndex, getDurationDays } from 'components/Grid/utils';
import { usePerfEnd } from 'contexts/performance';
import { motion } from 'framer-motion';
import { useCalendarDays } from 'hooks/useCalendar';
import { useFullEvent } from 'hooks/useEventsSelection';
import { useFocusTrap } from 'hooks/useFocusTrap';
import React, { useRef, useState } from 'react';
import { useLayer } from 'react-laag';
import { TEST_ID_POPOVER_EVENT } from 'utils/constants';
import PopoverContent from './PopoverContent';
interface Props {
  eventId: string;
  isOpen: boolean;
  anchorElement: Element;
}

export default React.memo(function PopoverLayout({
  eventId,
  isOpen,
  anchorElement,
}: Props): JSX.Element | null {
  usePerfEnd('open-event-popover');
  const [completedAnimation, setCompletedAnimation] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const event = useFullEvent(eventId);
  const days = useCalendarDays();
  const durationDays = event ? getDurationDays(event) : 0;
  const dayIndex = getDayIndex(days, event);
  const preferLeftStart = durationDays >= 2 && dayIndex && dayIndex >= 3;

  const { layerProps } = useLayer({
    isOpen: isOpen,
    placement: preferLeftStart ? 'left-start' : 'right-start',
    possiblePlacements: ['left-start', 'right-start'],
    auto: true,
    triggerOffset: 8,
    containerOffset: 12,
    trigger: {
      getBounds: () => anchorElement.getBoundingClientRect(),
    },
    ResizeObserver,
  });

  useFocusTrap({ active: isOpen, ref: popoverRef });

  return (
    <div
      {...layerProps}
      data-testid={TEST_ID_POPOVER_EVENT}
      className="pointer-events-none fixed z-100 flex w-72 transform-gpu"
      style={{
        maxHeight: 'calc(100vh - 24px)',
        willChange: 'top, left, right, bottom',
        transitionProperty: 'top left right bottom',
        transitionDuration: completedAnimation ? '0.24s' : '0s',
        transitionTimingFunction: 'ease-out',
        ...layerProps.style,
      }}
    >
      <motion.div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        ref={popoverRef}
        initial={{ x: -16, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -16, opacity: 0 }}
        onAnimationComplete={() => setCompletedAnimation(isOpen)}
        transition={{ duration: 0.12 }}
        className="bg-popover hidden-scrollbar pointer-events-auto flex w-full transform-gpu overflow-x-hidden overflow-y-scroll rounded-xl shadow-popover"
      >
        <PopoverContent key={`popover-${eventId}`} eventId={eventId} />
      </motion.div>
    </div>
  );
});
