import classNames from 'classnames';
import { useUser } from 'contexts/auth';
import Avatar from 'joy/Avatar';
import React, { useCallback } from 'react';

interface Props {
  avatar: string | undefined;
  displayName: string | null;
  count: number;
}

export default React.memo(function WidgetTogether({
  avatar,
  displayName,
  count,
}: Props) {
  const user = useUser();

  const getRandomProps = useCallback(
    (forceIndex?: number) => {
      const index = Math.floor(Math.random() * 2 + 1) - 1;
      return [
        { name: user?.displayName || '', src: user?.photo || '' },
        { name: displayName || '', src: avatar },
      ][forceIndex === undefined ? index : forceIndex];
    },
    [avatar, displayName, user]
  );

  if (!user) return null;

  return (
    <div className="relative flex h-full w-full flex-col overflow-hidden rounded-3xl bg-gradient-to-t from-purple-400 to-pink-200 p-6 text-white dark:from-purple-400/60 dark:to-pink-400/20">
      <div className="absolute inset-0 flex h-44 w-full overflow-hidden">
        <div className="flex h-full w-full rotate-6 flex-col gap-2.5">
          <div className="flex gap-2.5">
            {Array(10)
              .fill(null)
              .map((_, index) => (
                <Avatar
                  key={index}
                  className="opacity-10 ring ring-black/20 grayscale dark:ring-white/10"
                  size={40}
                  {...getRandomProps()}
                />
              ))}
          </div>
          <div className="ml-[-18px] flex gap-2.5">
            {Array(10)
              .fill(null)
              .map((_, index) => (
                <Avatar
                  key={index}
                  className={classNames({
                    'opacity-10 ring ring-black/20 grayscale dark:ring-white/10':
                      index < 3 || index > 4,
                    'ring ring-black/10 dark:ring-white/30':
                      index === 3 || index === 4,
                  })}
                  size={40}
                  {...getRandomProps(
                    index === 3 ? 0 : index === 4 ? 1 : undefined
                  )}
                />
              ))}
          </div>
          <div className="flex gap-2.5">
            {Array(10)
              .fill(null)
              .map((_, index) => (
                <Avatar
                  key={index}
                  className="opacity-10 ring ring-black/20 grayscale dark:ring-white/10"
                  size={40}
                  {...getRandomProps()}
                />
              ))}
          </div>
        </div>
      </div>

      <div className="mt-auto flex flex-col gap-1">
        <div className="flex items-baseline gap-1">
          <p className="text-4xl font-black">{count}</p>
          <p className="text-xl font-medium">
            {count === 1 ? 'time' : 'times'}
          </p>
        </div>
        <small className="mt-0.5 text-sm font-black uppercase leading-snug tracking-wider text-purple-100 dark:text-purple-200">
          Together this year
        </small>
      </div>
    </div>
  );
});
