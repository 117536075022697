import { useUpdateEffect } from '@react-aria/utils';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useEventElement } from 'hooks/useEventElement';
import { useEventsSelection } from 'hooks/useEventsSelection';
import { modalAtom, useUpdateModal } from 'hooks/useModal';
import React, { useEffect, useCallback, useMemo } from 'react';
import { ModalType } from 'types/modal';
import PopoverLayout from './PopoverLayout';
import { useSetEventsSelection } from 'hooks/useEventsSelection';
import usePrevious from 'hooks/usePrevious';
import { useSetVisibleGuestCalendars } from 'hooks/useVisibleCalendars';
import dynamic from 'next/dynamic';
import { useDndContext } from '@dnd-kit/core';
import { useAtomValue } from 'jotai/utils';

const Modal = dynamic(() => import('components/Modal'), { ssr: false });

export default React.memo(function Popover(): JSX.Element | null {
  const modal = useAtomValue(modalAtom);
  const { openModal, closeModal } = useUpdateModal();
  const { active } = useDndContext();

  const isDragging = active?.data?.current?.type === 'EVENT';

  const temporarilyHidden = isDragging;

  const eventsSelection = useEventsSelection();
  const { deleteDraftEvent, saveGridEvent } = useUpdateGridEvent();

  const { clearEventsSelection } = useSetEventsSelection();
  const { resetGuestCalendars } = useSetVisibleGuestCalendars();

  const eventId = eventsSelection[0];

  const anchorElement = useEventElement(eventId);
  const isModalOpen = useMemo(
    () => modal === ModalType.QuickMenu || modal === ModalType.Event,
    [modal]
  );
  const wasModalOpen = usePrevious(isModalOpen);

  // When the popover closes, it's our duty to create or save the event,
  // but oftentimes we are getting closed by clearing selected events,
  // instead of directly calling CloseModal. Keeping track of the last selected event's id
  // is more resilient than remembering to use CloseModal everywhere we use clearEventsSelection,
  // whilst also protecting us against other race conditions that could happen.
  const [lastSelectedEventId, setLastSelectedEventId] = React.useState<
    string | undefined
  >(eventId);
  const updateOrSaveEvent = useCallback(
    (eventId) => {
      if (!eventId) return;
      saveGridEvent({ id: eventId, forceSave: true });
      setLastSelectedEventId(undefined);
    },
    [saveGridEvent]
  );

  useEffect(() => {
    if (lastSelectedEventId === eventId) return;

    updateOrSaveEvent(lastSelectedEventId);

    if (eventId !== undefined) {
      setLastSelectedEventId(eventId);
    }
  }, [eventId, lastSelectedEventId, updateOrSaveEvent]);

  useUpdateEffect(() => {
    if (isModalOpen && !eventId) closeModal(ModalType.Event);
  }, [closeModal, openModal, eventId]);

  const handleClosePopover = useCallback(() => {
    resetGuestCalendars();
    deleteDraftEvent();
    if (eventsSelection.length <= 1) {
      clearEventsSelection();
    }
  }, [
    clearEventsSelection,
    deleteDraftEvent,
    eventsSelection.length,
    resetGuestCalendars,
  ]);

  useEffect(() => {
    if (wasModalOpen && !isModalOpen && !temporarilyHidden) {
      handleClosePopover();
    }
  }, [handleClosePopover, isModalOpen, temporarilyHidden, wasModalOpen]);

  return (
    <Modal
      id={ModalType.Event}
      scope="modal"
      style={{
        opacity: temporarilyHidden ? 0 : undefined,
      }}
    >
      {anchorElement && !temporarilyHidden && (
        <PopoverLayout
          eventId={eventId}
          isOpen={isModalOpen}
          anchorElement={anchorElement}
        />
      )}
    </Modal>
  );
});
