import { SyncBirthday } from 'hooks/useBirthday';
import { SyncProfile } from 'hooks/useProfile';
import { SyncProfileFollow } from 'hooks/useProfileFollow';
import { useProfileKey } from 'hooks/useProfileKey';
import { SyncProfileSeen } from 'hooks/useProfileSeen';
import React from 'react';
import ProfileContent from './ProfileContent';

export default React.memo(function Profile() {
  const email = useProfileKey();

  if (!email) return null;

  return (
    <>
      <SyncBirthday email={email} />
      <SyncProfile email={email} />
      <SyncProfileSeen email={email} />
      <SyncProfileFollow email={email} />
      <ProfileContent email={email} />
    </>
  );
});
