import classNames from 'classnames';
import IconTwitter from 'components/Settings/icons/IconTwitter';
import { SettingsPageType } from 'components/Settings/types';
import { useUpdateModal, useUpdateSettingPageModal } from 'hooks/useModal';
import Button from 'joy/Button';
import React, { useCallback } from 'react';
import { ModalType } from 'types/modal';

interface Props {
  firstName: string | null;
  isSelf: boolean;
}

export default React.memo(function WidgetTwitterEmpty({
  isSelf,
  firstName,
}: Props) {
  const { openModal } = useUpdateModal();
  const setSettingsPage = useUpdateSettingPageModal();
  const emptyDescription =
    firstName === null
      ? 'Twitter account has not been linked.'
      : `${firstName} has not yet linked their Twitter account.`;
  const description = isSelf
    ? 'Let people know who you are on Twitter.'
    : emptyDescription;

  const connect = useCallback(() => {
    setSettingsPage(SettingsPageType.Profile);
    openModal(ModalType.Settings);
  }, [openModal, setSettingsPage]);

  return (
    <div
      className={classNames(
        'flex flex-col bg-gradient-to-t dark:bg-gradient-to-b w-full h-full rounded-3xl p-6',
        {
          'from-twitter to-twitter/60 text-white': isSelf,
          'from-gray-100 to-gray-50 dark:from-gray-600/10 dark:to-gray-800/40':
            !isSelf,
        }
      )}
    >
      <div
        className={classNames('flex justify-between w-full', {
          'text-twitter': !isSelf,
        })}
      >
        <IconTwitter className="w-10 h-10" />
      </div>

      <div className="flex flex-col w-full mt-auto">
        <small
          className={classNames(
            'mb-0.5 text-sm font-black tracking-wider uppercase',
            {
              'text-white/60': isSelf,
              'text-secondary': !isSelf,
            }
          )}
        >
          Twitter
        </small>

        <p
          className={classNames(
            'text-xl font-black leading-tight break-words',
            { 'text-secondary': !isSelf }
          )}
        >
          {description}
        </p>

        {isSelf && (
          <Button
            onClick={connect}
            className="flex items-center justify-center w-full py-3 mt-4 text-sm font-semibold bg-white rounded-full text-twitter hover:bg-white/80"
          >
            Connect Twitter
          </Button>
        )}
      </div>
    </div>
  );
});
