import IconCake from 'components/Icons/IconCake';
import IconQuestionMarkCircle from 'components/Icons/IconQuestionMarkCircle';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { WidgetBirthdayPicker } from './WidgetBirthdayPicker';

interface Props {
  date: string | null;
  isSelf: boolean;
  isSuggested: boolean;
  setDate: (date: Date) => Promise<void>;
}

export default function WidgetBirthday({
  date,
  isSelf,
  isSuggested,
  setDate,
}: Props) {
  const parsedDate = date ? DateTime.fromISO(date) : DateTime.now();
  const hasValidDate = date != null && parsedDate.isValid;
  const relativeDate =
    parsedDate.ordinal < DateTime.now().ordinal
      ? parsedDate.plus({ year: 1 }).toRelativeCalendar({ unit: 'days' })
      : parsedDate
          .set({ year: DateTime.now().year })
          .toRelativeCalendar({ unit: 'days' });

  const unsetDescription = isSelf
    ? `You haven't set your birthday yet!`
    : 'Do you know it?';

  return (
    <div className="relative flex h-full w-full flex-col overflow-hidden rounded-3xl bg-gradient-to-t from-white to-white p-6 dark:bg-gradient-to-b dark:from-gray-600/10 dark:to-gray-800/40">
      <div className="fade-bg absolute inset-0 z-0 h-32 w-full">
        <div
          className="bg-pattern-birthday h-full w-full animate-backgroundBirthdayScroll bg-repeat-y opacity-5"
          style={{
            height: 128 * 2,
            backgroundSize: '100%',
          }}
        />
      </div>

      <div className="relative z-20 mt-auto flex flex-col">
        <div className="mb-0.5 flex gap-0.5 text-pink-400 dark:text-pink-200">
          <small className="text-sm font-black uppercase tracking-wider">
            {hasValidDate ? `Birthday` : 'Birthday not set'}
          </small>
          {isSuggested && (
            <Tooltip content="Not yet verified by profile owner">
              <small className="flex cursor-default text-sm font-black uppercase tracking-wider opacity-60">
                <IconQuestionMarkCircle />
              </small>
            </Tooltip>
          )}
        </div>

        <p className="text-xl font-black">
          {hasValidDate ? parsedDate.toFormat('MMMM d') : unsetDescription}
        </p>

        {hasValidDate && (
          <small className="text-secondary mt-0.5 text-base font-medium">
            {capitalize(relativeDate || '')}
          </small>
        )}

        {!hasValidDate && (
          <div className="mt-4 flex w-full">
            <WidgetBirthdayPicker date={date as string} setDate={setDate}>
              <Button className="flex w-full items-center justify-center rounded-full bg-gray-200 py-3 text-sm font-semibold dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mr-1.5 flex text-gray-400 dark:text-gray-500">
                  <IconCake />
                </div>
                {isSelf ? 'Set date' : 'Suggest date'}
              </Button>
            </WidgetBirthdayPicker>
          </div>
        )}
      </div>
    </div>
  );
}
