import { DraggableSyntheticListeners, useDraggable } from '@dnd-kit/core';
import { NewEventRsvpEnum } from '@graphql-types@';
import { useGridEventContextMenu } from 'components/Grid/GridEventContextMenu';
import { isMultiDay } from 'components/Grid/utils';
import { perfStart } from 'contexts/performance';
import { useAllDayRowsPosition } from 'hooks/events/useAllDayEvents';
import { useEventOverlap } from 'hooks/events/useEventOverlap';
import {
  useGridEvent,
  useInteractionOnlyEvent,
} from 'hooks/events/useGridEvents';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useCalendarModeValue } from 'hooks/useCalendarMode';
import { useSetEventElement } from 'hooks/useEventElement';
import {
  eventsSelectionAtom,
  useIsEventSelected,
  useSetEventsSelection,
} from 'hooks/useEventsSelection';
import { modalAtom, useUpdateModal } from 'hooks/useModal';
import { useHideDeclinedEvents, useUi24HourClock } from 'hooks/usePreferences';
import { useSelectEventsInRange } from 'hooks/useSelectEventsInRange';
import { useAtomCallback } from 'jotai/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { ModalType } from 'types/modal';
import { throttleAction } from 'utils/toolbox';
import { IGridDay, IGridEvent } from '../../types/events';
import GridEvent from './GridEvent';
import { getDayIndex, getEventStyle, isDraftEvent, isEvent } from './utils';

interface Props {
  eventId: string;
  allDay?: boolean;
  days: IGridDay[];
  type: DraggableType;
}

export default React.memo(
  function GridEventWrapper({ days, allDay, eventId, type }: Props) {
    const gridEvent = useGridEvent(eventId);
    const realtimeInteractionEvent = useInteractionOnlyEvent(eventId);
    const event = useMemo(
      () => ({ ...gridEvent, ...realtimeInteractionEvent } as IGridEvent),
      [gridEvent, realtimeInteractionEvent]
    );
    const [isDragging, setIsDragging] = useState(false);
    const [listeners, setListeners] =
      useState<DraggableSyntheticListeners | null>(null);
    const [attributes, setAttributes] = useState<DraggableAttributes | null>(
      null
    );
    const [nodeRef, setNodeRef] = useState<HTMLDivElement | null>(null);

    const overlap = useEventOverlap(eventId);
    const selected = useIsEventSelected(eventId);
    const ui24HourClock = useUi24HourClock();
    const hideDeclinedEvents = useHideDeclinedEvents();

    const { onContextMenuClose, ctxMenuItems, onContextMenuOpen } =
      useGridEventContextMenu(event);
    const calendarLinkMode = useCalendarModeValue();
    const { getRowPosition } = useAllDayRowsPosition();

    const setEventElement = useSetEventElement(eventId);
    const { deselectEvent, selectEvent } = useSetEventsSelection();
    const {
      updateGridEventForInteractionOnly,
      updateGridEvent,
      saveGridEvent,
      deleteDraftEvent,
      revertGridEvent,
      applyInteractionOnlyChanges,
    } = useUpdateGridEvent();

    const selectEventsInRange = useSelectEventsInRange();
    const { openModal, closeModal } = useUpdateModal();

    const setElementRef = useCallback(
      (element: HTMLDivElement | null) => {
        setNodeRef(element);
        setEventElement(element);
      },
      [setEventElement, setNodeRef]
    );

    const updateEventDoneAt = useCallback(() => {
      if (!event) return;

      const doneAt = event.doneAt ? null : new Date().toISOString();

      if (isDraftEvent(event)) {
        updateGridEvent({
          id: event.id,
          doneAt,
        });
      }

      if (isEvent(event)) {
        saveGridEvent({
          id: event.id,
          doneAt,
        });
      }
    }, [event, saveGridEvent, updateGridEvent]);

    const handleCheckboxChange = useCallback(() => {
      throttleAction(updateEventDoneAt);
    }, [updateEventDoneAt]);

    const onClick = useAtomCallback(
      useCallback(
        (get, _set, mouseEvent: React.MouseEvent<HTMLDivElement>) => {
          mouseEvent.stopPropagation();
          const eventsSelection = get(eventsSelectionAtom);
          const isSelected = eventsSelection.includes(eventId);
          const isPressingShift = mouseEvent.shiftKey;
          const isPressingMeta = mouseEvent.metaKey || mouseEvent.ctrlKey;
          const hasSingleSelection = eventsSelection.length === 1;
          const hasMultiSelection = eventsSelection.length > 1;

          switch (true) {
            // Handle typical single deselection
            case isSelected && hasSingleSelection:
              deselectEvent(eventId);
              break;

            // Handle deselection of single event in a multi selection on click with meta key
            case isSelected && hasMultiSelection && isPressingMeta:
              deselectEvent(eventId);
              break;

            // Handle selection of single event in a multi selection on click
            case isSelected && hasMultiSelection && !isPressingMeta:
              deleteDraftEvent();
              selectEvent(eventId, true);
              openModal(ModalType.Event);
              setEventElement(mouseEvent.currentTarget);
              break;

            // Handle range selection on click with shift key
            case !isSelected && hasSingleSelection && isPressingShift:
              closeModal(ModalType.Event);
              selectEventsInRange(eventId);
              return;

            case !isSelected && isPressingMeta:
              closeModal(ModalType.Event);
              selectEvent(eventId);
              return;

            // Handle the default case of clicking and selecting a single event
            default:
              perfStart('open-event-popover');
              deleteDraftEvent();
              selectEvent(eventId, !isPressingMeta);
              setEventElement(mouseEvent.currentTarget);

              return isPressingMeta
                ? closeModal(ModalType.Event)
                : openModal(ModalType.Event);
          }
        },
        [
          eventId,
          deselectEvent,
          selectEvent,
          selectEventsInRange,
          deleteDraftEvent,
          setEventElement,
          closeModal,
          openModal,
        ]
      )
    );

    const dayIndex = useMemo(() => getDayIndex(days, event), [days, event]);

    const style = useMemo(
      () =>
        event
          ? {
              ...getEventStyle({
                event: {
                  ...event,
                  overlap,
                  startAt: event.startAt,
                  endAt: event.endAt,
                },
                dayIndex,
                rowPosition: getRowPosition(event.id),
              }),
              ...(isDragging
                ? { zIndex: 99, pointerEvents: 'none' }
                : { pointerEvents: 'auto' }),
            }
          : {},
      [event, overlap, dayIndex, getRowPosition, isDragging]
    );

    const eventBelongsInEventWrapper =
      (event?.isAllDay || isMultiDay(event)) === allDay && dayIndex > 0;

    const rsvpStatus = event?.isSelfAsAttendee
      ? event.rsvp
      : event?.rsvpForCalendarId;

    const hideEvent =
      !event ||
      !eventBelongsInEventWrapper ||
      (hideDeclinedEvents && rsvpStatus === NewEventRsvpEnum.No) ||
      event?.status === 'cancelled';

    const onResizeEnd = useAtomCallback(
      useCallback(
        (get) => {
          if (!event || get(modalAtom) === ModalType.Event) return;
          applyInteractionOnlyChanges({ id: event.id });
          saveGridEvent({ id: event.id });
        },
        [applyInteractionOnlyChanges, event, saveGridEvent]
      )
    );

    if (hideEvent) return null;

    return (
      <>
        <UseDraggable
          id={eventId + event?.calendarId}
          event={event}
          type={type}
          disabled={
            !event?.canEdit || type === DraggableType.NONE || !event.isOwnEvent
          }
          setIsDragging={setIsDragging}
          setAttributes={setAttributes}
          setListeners={setListeners}
          nodeRef={nodeRef}
        />
        <GridEvent
          onContextMenuClose={onContextMenuClose}
          ctxMenuItems={ctxMenuItems}
          onContextMenu={onContextMenuOpen}
          ref={setElementRef}
          event={event}
          isGhost={isDragging}
          onClick={onClick}
          style={style}
          ui24HourClock={ui24HourClock}
          handleCheckboxChange={handleCheckboxChange}
          selected={selected}
          disabled={calendarLinkMode === 'selecting-one-off-slots'}
          onResize={({ startAt, endAt }) =>
            updateGridEventForInteractionOnly({
              id: event.id,
              startAt,
              endAt,
            })
          }
          onResizeEnd={onResizeEnd}
          onResizeCancel={() => revertGridEvent({ id: event.id })}
          {...listeners}
          {...attributes}
        />
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.eventId === nextProps.eventId &&
    prevProps.allDay === nextProps.allDay
);

type DraggableAttributes = ReturnType<typeof useDraggable>['attributes'];
interface UseDraggableProps {
  id: string;
  event: IGridEvent;
  disabled: boolean;
  type: DraggableType;
  setIsDragging: (isDragging: boolean) => void;
  setListeners: (listeners: DraggableSyntheticListeners) => void;
  setAttributes: (attributes: DraggableAttributes) => void;
  nodeRef: HTMLDivElement | null;
}

function UseDraggable(props: UseDraggableProps) {
  const { setNodeRef, listeners, attributes, isDragging } = useDraggable({
    id: props.id,
    disabled: props.disabled,
    data: { type: props.type, event: props.event },
  });

  useEffect(() => {
    props.setIsDragging(isDragging);
    props.setAttributes(attributes);
    props.setListeners(listeners);
    setNodeRef(props.nodeRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return null;
}
