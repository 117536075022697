import {
  useUserAvatar,
  useUserEmail,
  useUserId,
  useUserName,
} from 'contexts/auth';
import { useEffect } from 'react';
import { DataAttributesAvatar, useIntercom } from 'react-use-intercom';

export function IntercomWidget() {
  const { boot, shutdown } = useIntercom();
  const userId = useUserId();
  const email = useUserEmail();
  const name = useUserName();
  const userAvatar = useUserAvatar();

  useEffect(() => {
    const avatar = userAvatar
      ? ({
          type: 'avatar',
          imageUrl: userAvatar,
        } as DataAttributesAvatar)
      : undefined;
    boot({
      userId,
      email,
      name,
      avatar,
    });
    return () => shutdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, email, name, userAvatar]);
  return null;
}
