import { useUserEmail } from 'contexts/auth';
import useProfile from 'hooks/useProfile';
import useProfileSeen from 'hooks/useProfileSeen';
import React from 'react';
import ProfileHeader from './ProfileHeader';
import WidgetMap from './WidgetMap';
import ProfileWidget from './ProfileWidget';
import ProfileWidgets from './ProfileWidgets';
import WidgetBirthday from './WidgetBirthday';
import WidgetMeeting from './WidgetMeeting';
import WidgetReminder from './WidgetReminder';
import WidgetSpotify from './WidgetSpotify';
import WidgetTogether from './WidgetTogether';
import WidgetTwitter from './WidgetTwitter';
import useBirthday, { useBirthdayUpdate } from 'hooks/useBirthday';
import { isSameDomain } from 'utils/parsing';
import WidgetNote from './WidgetNote';

interface Props {
  email: string;
}

export default React.memo(function ProfileContent({ email }: Props) {
  const userEmail = useUserEmail();
  const profile = useProfile(email);
  const seenInfo = useProfileSeen(email);
  const isSelf = userEmail === email;
  const isExternalUser = !isSameDomain(userEmail || '', email);
  const { date, isSuggested } = useBirthday(email);
  const { setDate } = useBirthdayUpdate(email);

  const displayName =
    profile?.firstName === null
      ? null
      : `${profile?.firstName} ${profile?.lastName}`;

  if (!profile) return null;

  return (
    <div className="flex min-h-full w-full flex-col items-center overflow-y-auto pt-12 pb-20">
      <div className="flex w-full max-w-4xl flex-col px-6">
        <ProfileHeader
          isSelf={isSelf}
          isExternalUser={isExternalUser}
          avatar={profile.photoUrl || ''}
          displayName={displayName}
          email={profile.email}
          handle={profile.handle}
          birthday={date}
          twitter={profile.twitter || undefined}
          location={profile.lastLocation || undefined}
          verified={typeof profile.verifiedAt === 'string'}
        />

        <ProfileWidgets>
          {!isSelf && (
            <ProfileWidget>
              <WidgetTogether
                avatar={profile.photoUrl || ''}
                displayName={displayName}
                count={seenInfo?.numberOfTimesMeet || 0}
              />
            </ProfileWidget>
          )}
          <ProfileWidget>
            <WidgetBirthday
              date={date}
              isSelf={isSelf}
              isSuggested={isSuggested}
              setDate={setDate}
            />
          </ProfileWidget>

          <ProfileWidget>
            <WidgetNote key={profile.id} email={profile.email} />
          </ProfileWidget>

          <ProfileWidget span={2}>
            <WidgetMap
              displayName={displayName}
              avatar={profile.photoUrl}
              location={profile.lastLocation || null}
            />
          </ProfileWidget>

          {seenInfo && !isSelf && (
            <ProfileWidget>
              <WidgetReminder
                firstName={profile.firstName}
                contactId={profile.contactId}
                lastSeen={seenInfo.lastSeen}
                lastSeenSource={seenInfo.lastSeenSource}
                numberOfTimesMeet={seenInfo.numberOfTimesMeet}
              />
            </ProfileWidget>
          )}

          <ProfileWidget clickable={typeof profile.twitter === 'string'}>
            <WidgetTwitter
              isSelf={isSelf}
              firstName={profile.firstName || null}
              handle={profile.twitter || undefined}
            />
          </ProfileWidget>

          <ProfileWidget>
            <WidgetSpotify
              isSelf={isSelf}
              firstName={profile.firstName || null}
              email={profile.email}
            />
          </ProfileWidget>

          {!isExternalUser && (
            <ProfileWidget>
              <WidgetMeeting
                showViewCalendar={displayName !== null && !isSelf}
                email={profile.email}
              />
            </ProfileWidget>
          )}
        </ProfileWidgets>
      </div>
    </div>
  );
});
