import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import IconCheckboxMark from 'components/Icons/IconCheckboxMark';
import IconCopy from 'components/Icons/IconCopy';
import IconXMark from 'components/Icons/IconXMark';
import { useCalendarColor } from 'components/Panels/useAvailableCalendars';
import { useUserEmail } from 'contexts/auth';
import { useIsContactCalendarLoading } from 'hooks/contacts/useIsContactCalendarLoading';
import { useGridEvent } from 'hooks/events/useGridEvents';
import { useContactsValue } from 'hooks/useContacts';
import { useEventsSelection } from 'hooks/useEventsSelection';
import {
  useSetVisibleGuestCalendars,
  useVisibleCalendarIds,
} from 'hooks/useVisibleCalendars';
import Avatar from 'joy/Avatar';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Attendee } from 'types/events';
import { getAvatarStatus } from './utils';

interface Props {
  expanded: boolean;
  attendee: Attendee;
  readOnly?: boolean;
  hasFocus?: boolean;
  deleteAttendee: (attendeeId: string, attendeeEmail: string) => void;
}

export default function EventAttendee({
  attendee,
  expanded,
  readOnly,
  deleteAttendee,
}: Props): React.ReactElement {
  const contacts = useContactsValue();
  const [currentEventId] = useEventsSelection();
  const currentEvent = useGridEvent(currentEventId);

  const isOrganizer = useMemo(() => {
    const organizer = currentEvent?.attendees.find((a) => a.organizer);
    return organizer?.email === attendee.email;
  }, [attendee, currentEvent]);

  const attendeeEmail = attendee.email;

  const attendeeDisplayName = attendee.displayName;

  const attendeeNameOrEmail = attendeeDisplayName || attendeeEmail;

  const attendeeAvatarSrc =
    attendee.avatar ||
    contacts.find((x) => x.emailAddress === attendeeEmail)?.avatar ||
    undefined;

  const [isCopied, setIsCopied] = useState(false);

  const copiedTimer = useRef(0);

  const userEmail = useUserEmail();

  const visibleCalendars = useVisibleCalendarIds();

  const { addGuestCalendar, removeGuestCalendar } =
    useSetVisibleGuestCalendars();

  const isLoading = useIsContactCalendarLoading(attendeeEmail);

  // using visible calendars here instead of available will allow us
  // to toggle our own calendar visibility from within the guests section
  const isAvatarActive = useMemo(
    () => visibleCalendars.some((email) => email === attendeeEmail),
    [visibleCalendars, attendeeEmail]
  );

  const avatarCalendarColor = useCalendarColor(attendeeEmail);

  const canToggleCalendar = userEmail !== attendeeEmail && attendeeDisplayName;

  const toggleCalendarsCopy = isAvatarActive
    ? `Hide calendar`
    : `Show calendar`;

  const toggleContactVisibility = useCallback(
    (email) => {
      if (isAvatarActive && visibleCalendars.length !== 1) {
        removeGuestCalendar(email);
      } else {
        addGuestCalendar(email);
      }
    },
    [
      isAvatarActive,
      visibleCalendars.length,
      removeGuestCalendar,
      addGuestCalendar,
    ]
  );

  const clearCopiedTimer = useCallback(() => {
    window.clearTimeout(copiedTimer.current);
    setIsCopied(false);
  }, []);

  const onClickCopy = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      navigator.clipboard.writeText(attendee.email);
      setIsCopied(true);
      copiedTimer.current = window.setTimeout(clearCopiedTimer, 3000);
    },
    [attendee.email, clearCopiedTimer]
  );

  if (expanded) {
    return (
      <div
        className={classNames(
          { ['cursor-pointer']: canToggleCalendar },
          'group flex h-6 w-full items-center truncate'
        )}
        onMouseLeave={() => setTimeout(clearCopiedTimer, 75)}
      >
        <Tooltip
          placement="bottom-center"
          content={toggleCalendarsCopy}
          disabled={!canToggleCalendar}
        >
          <Avatar
            src={attendeeAvatarSrc}
            name={attendeeEmail}
            size={24}
            status={getAvatarStatus(attendee.RSVP)}
            ring={
              isAvatarActive
                ? attendeeEmail === userEmail
                  ? ColorFamily.Gray
                  : avatarCalendarColor
                : undefined
            }
            className={classNames('transition-opacity group-hover:opacity-100')}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              // prevent hiding own calendar
              // only allow showing calendar if display name is present,
              // i.e. do not attempt to show for group emails and external calendars
              if (!canToggleCalendar) return;
              toggleContactVisibility(attendeeEmail);
            }}
          />
        </Tooltip>
        <small className="ml-2 truncate font-medium">
          {attendeeNameOrEmail}
        </small>
        <div className="ml-1.5 flex space-x-1">
          <Tooltip content={`Copy ${attendeeEmail}`}>
            <Button
              onMouseDown={(event: React.MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              onClick={onClickCopy}
              className={
                'flex h-5 w-5 scale-0 items-center justify-center rounded-full bg-gray-200 text-gray-500 opacity-0 transition delay-75 duration-150 hover:!scale-105 active:!scale-100 group-hover:scale-100 group-hover:opacity-100 group-hover:delay-[0ms] dark:bg-gray-500 dark:text-gray-300'
              }
            >
              {isCopied ? (
                <IconCheckboxMark height={12} width={12} />
              ) : (
                <IconCopy className="h-2.5 w-2.5" />
              )}
            </Button>
          </Tooltip>
          {attendeeEmail !== userEmail && (
            <Tooltip content="Remove" disabled={readOnly}>
              <Button
                onMouseDown={(event: React.MouseEvent) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  event.stopPropagation();
                  deleteAttendee(attendee.id, attendee.email);
                }}
                className={classNames(
                  'flex h-5 w-5 scale-0 items-center justify-center rounded-full bg-gray-200 text-gray-500 opacity-0 transition duration-150 active:!scale-100 group-hover:scale-100 group-hover:delay-75 dark:bg-gray-500 dark:text-gray-300',
                  {
                    'hover:!scale-105 hover:bg-red-500 hover:text-white group-hover:opacity-100 dark:hover:bg-red-500 dark:hover:text-white ':
                      !readOnly,
                    'cursor-not-allowed group-hover:opacity-50': readOnly,
                  }
                )}
              >
                <IconXMark className="h-2.5 w-2.5" />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  return (
    <Tooltip
      content={
        <div className="flex items-center">
          <Avatar
            src={attendeeAvatarSrc}
            name={attendeeEmail}
            status={getAvatarStatus(attendee.RSVP)}
            statusClassName="border-black border-2"
          />
          <div className="ml-2 flex flex-col">
            <small className="text-xs font-medium">
              {attendeeNameOrEmail}
              <span className=" text-gray-400">
                {isOrganizer && ` (organizer)`}
              </span>
            </small>

            {canToggleCalendar && (
              <small className="text-[11px] text-gray-400">
                {toggleCalendarsCopy}
              </small>
            )}
          </div>
        </div>
      }
    >
      <div
        className={classNames('group relative', {
          ['cursor-pointer']: canToggleCalendar,
        })}
      >
        <Avatar
          src={attendeeAvatarSrc}
          name={attendeeEmail}
          status={getAvatarStatus(attendee.RSVP)}
          isLoading={isLoading}
          size={24}
          ring={
            isAvatarActive
              ? attendeeEmail === userEmail
                ? ColorFamily.Gray
                : avatarCalendarColor
              : undefined
          }
          className={classNames('transition-opacity group-hover:opacity-100')}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            // prevent hiding own calendar
            // only allow showing calendar if display name is present,
            // i.e. do not attempt to show for group emails and external calendars
            if (!canToggleCalendar) return;
            toggleContactVisibility(attendeeEmail);
          }}
        />
      </div>
    </Tooltip>
  );
}
