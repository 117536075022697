import {
  DragCancelEvent,
  DragEndEvent,
  DragMoveEvent,
  useDndContext,
  useDndMonitor,
} from '@dnd-kit/core';
import { sidebarWidthAtom } from 'components/Panels/SidePanel';
import {
  CONTACTPANEL_WIDTH,
  GRID_TIMESTAMPS_WIDTH,
  TODO_HEADER_SECTION_HEIGHT,
} from 'components/Panels/utils';
import { clearDragAtom } from 'components/Todos/todosAtoms';
import { useIsMouseInRange, useMousePosition } from 'components/Todos/utils';
import { generateEventUUID } from 'hooks/events/helpers/eventsHelpers';
import {
  applyInteractionOnlyChangesAtomCallback,
  useUpdateGridEvent,
} from 'hooks/events/useUpdateGridEvent';
import {
  eventsSelectionAtom,
  fullSelectionEventsAtom,
} from 'hooks/useEventsSelection';
import { useAltKey } from 'hooks/useHotkey';
import { expandedAtom } from 'hooks/useInterface';
import { modalAtom } from 'hooks/useModal';
import { useAtomCallback, useAtomValue, useUpdateAtom } from 'jotai/utils';
import React, { useCallback, useEffect, useRef } from 'react';
import { DraggableType, DroppableId } from 'types/drag-and-drop';
import { IGridEvent } from 'types/events';
import { ModalType } from 'types/modal';
import { roundDateTimeToNearestQuarterHour } from 'utils/mouseEvents';
import { MINUTES_IN_A_DAY } from 'utils/time';
import { getGridMouseCoordinates } from './clickHandler/GridInteractionsHandler';
import { useGetDatePoint } from './clickHandler/helpers/dragHelpers';
import GridEvent from './GridEvent';
import { isDraftEvent, isEventGhostEnabled } from './utils';

export default function GridEventGhost() {
  const { active, activeNodeClientRect } = useDndContext();
  const {
    deleteDraftEvent,
    updateGridEventForInteractionOnly,
    saveGridEvent,
    revertGridEvent,
    createDraftEvent,
  } = useUpdateGridEvent();
  const altPressed = useAltKey();
  const affectedEvents = useRef<IGridEvent[] | null>(null);
  const lastUpdatedAt = useRef(Date.now());

  const clearTodosDrag = useUpdateAtom(clearDragAtom);
  const getDatePoint = useGetDatePoint();

  useEffect(() => {
    if (active?.data.current?.type === DraggableType.EVENT) {
      document.body.classList.add('cursor-[grabbing]');
    } else {
      document.body.classList.remove('cursor-[grabbing]');
    }
  }, [active]);

  const draggingOffsetRef = useRef<number | null>(null);
  const draggingGhostOffsetRef = useRef<{ x: number; y: number } | null>(null);
  const { x, y } = useMousePosition();

  const getAffectedEvents = useAtomCallback(
    useCallback((get, _, event: IGridEvent) => {
      const fullEvents = get(fullSelectionEventsAtom).sort(
        (eventA, eventB) =>
          eventA.startAt.toMillis() - eventB.startAt.toMillis()
      );
      if (fullEvents.includes(event)) return fullEvents;
      if (!event) return []; // Fail safe return
      return [event];
    }, [])
  );

  const onDragEnd = useAtomCallback(
    useCallback(
      (get, set, dragEvent: DragEndEvent) => {
        if (!isEventGhostEnabled(dragEvent.active)) return;
        const selectedEvents = get(eventsSelectionAtom);
        const isModalEventModalOpen = get(modalAtom) === ModalType.Event;
        affectedEvents.current?.forEach((event) =>
          applyInteractionOnlyChangesAtomCallback(get, set, { id: event.id })
        );
        if (affectedEvents.current && !isModalEventModalOpen)
          affectedEvents.current.forEach((gridEvent) => {
            if (selectedEvents.length === 1 && !isDraftEvent(gridEvent)) return;
            saveGridEvent({ id: gridEvent.id });
          });

        affectedEvents.current = null;
        draggingOffsetRef.current = null;
        draggingGhostOffsetRef.current = null;
      },
      [saveGridEvent]
    )
  );

  const revertAffectedEvents = useCallback(() => {
    if (affectedEvents.current)
      affectedEvents.current.forEach(({ id }) => revertGridEvent({ id }));
  }, [affectedEvents, revertGridEvent]);

  useDndMonitor({
    onDragStart: async (dragEvent) => {
      if (altPressed) {
        const copyEvent = {
          ...dragEvent.active.data.current?.event,
          doneAt: undefined,
          isDraft: true,
          id: generateEventUUID(),
        };
        affectedEvents.current = [copyEvent];
        return createDraftEvent(copyEvent);
      } else {
        affectedEvents.current = await getAffectedEvents(
          dragEvent.active?.data.current?.event
        );
      }
    },
    onDragMove: async (dragEvent: DragMoveEvent) => {
      if (!isEventGhostEnabled(dragEvent.active)) return;

      if (dragEvent.over?.id !== DroppableId.SCHEDULE) return;
      const pointDate = getDatePoint(
        dragEvent,
        getGridMouseCoordinates(),
        false
      );

      // Ignore new position if invalid or is allday
      if (!pointDate) return;

      // Performance optimization. Don't update if the event is not affected
      if (lastUpdatedAt.current === pointDate.toMillis()) {
        return;
      }
      lastUpdatedAt.current = pointDate.toMillis();

      if (draggingOffsetRef.current === null) {
        const activeEventStartAt = dragEvent.active.data.current?.event.startAt;
        const activeEventEndAt = dragEvent.active.data.current?.event.endAt;
        if (!activeEventStartAt || !activeEventEndAt) return;

        if (activeNodeClientRect) {
          draggingGhostOffsetRef.current = {
            x: x - activeNodeClientRect.left,
            y: y - activeNodeClientRect.top,
          };
        }
        if (
          pointDate.toMillis() < activeEventStartAt.toMillis() ||
          pointDate.toMillis() > activeEventEndAt.toMillis()
        ) {
          draggingOffsetRef.current =
            activeEventEndAt.diff(activeEventStartAt).as('minutes') / 2;
        } else {
          draggingOffsetRef.current =
            pointDate.diff(activeEventStartAt).as('minutes') % MINUTES_IN_A_DAY;
        }
      }

      if (affectedEvents.current) {
        const draggingOffsetMinutes = draggingOffsetRef.current || 0;
        affectedEvents.current.reduce((totalPrevDraggedEventsTime, event) => {
          const duration = event.endAt.diff(event.startAt, 'minutes').minutes;
          const newStartAt = roundDateTimeToNearestQuarterHour(
            pointDate.plus({
              minutes: totalPrevDraggedEventsTime - draggingOffsetMinutes,
            })
          );
          const newEndAt = newStartAt.plus({
            minutes: duration,
          });

          if (
            newStartAt.ordinal !== pointDate.ordinal ||
            newEndAt.ordinal !== pointDate.ordinal
          ) {
            return totalPrevDraggedEventsTime;
          }
          updateGridEventForInteractionOnly({
            id: event.id,
            startAt: newStartAt,
            endAt: newEndAt,
          });
          return totalPrevDraggedEventsTime + duration;
        }, 0);
      }
    },
    onDragCancel: async (dragEvent: DragCancelEvent) => {
      if (!isEventGhostEnabled(dragEvent.active)) return;

      deleteDraftEvent();
      revertAffectedEvents();
      affectedEvents.current = null;
      clearTodosDrag();
    },
    onDragEnd: async (dragEvent: DragEndEvent) => {
      onDragEnd(dragEvent);
    },
  });

  const sidebarWidth = useAtomValue(sidebarWidthAtom);

  const showEventGhost = useIsMouseInRange({
    x: CONTACTPANEL_WIDTH + sidebarWidth + GRID_TIMESTAMPS_WIDTH + 7,
    y: TODO_HEADER_SECTION_HEIGHT,
  });

  const sidebarExpanded = useAtomValue(expandedAtom);
  useEffect(() => {
    if (!active) {
      return;
    }

    if (altPressed) {
      revertAffectedEvents();
    }
  }, [active, altPressed, deleteDraftEvent, revertAffectedEvents]);

  // Hide todo ghost if sidebar is expanded and cursor cross to-dos boundaries
  if (sidebarExpanded && !showEventGhost) return null;

  const event = (active?.data.current?.event as IGridEvent) || null;
  if (!event || !activeNodeClientRect) return null;

  const ghostOffset = draggingGhostOffsetRef.current;
  return (
    <GridEvent
      className="pointer-events-none z-200 opacity-90"
      style={{
        position: 'fixed',
        left: x - (ghostOffset?.x || 0),
        top: y - (ghostOffset?.y || 0),
        width: activeNodeClientRect.width + 'px',
        height: activeNodeClientRect.height + 'px',
      }}
      event={event}
    />
  );
}
