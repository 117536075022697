import classNames from 'classnames';
import { SettingsPageType } from 'components/Settings/types';
import { useUpdateModal, useUpdateSettingPageModal } from 'hooks/useModal';
import Button from 'joy/Button';
import React, { useCallback } from 'react';
import { ModalType } from 'types/modal';

interface Props {
  firstName: string | null;
  isSelf: boolean;
}

export default function WidgetSpotifyEmpty({ firstName, isSelf }: Props) {
  const { openModal } = useUpdateModal();
  const setSettingsPage = useUpdateSettingPageModal();

  const emptyDescription =
    firstName === null
      ? 'Spotify account has not yet been linked.'
      : `${firstName} has not yet linked their Spotify account.`;

  const description = isSelf
    ? 'Play something. Connect with Spotify to share activity.'
    : emptyDescription;

  const connect = useCallback(() => {
    setSettingsPage(SettingsPageType.Connections);
    openModal(ModalType.Settings);
  }, [openModal, setSettingsPage]);

  return (
    <div
      className={classNames(
        'flex flex-col bg-gradient-to-t dark:bg-gradient-to-b w-full h-full  rounded-3xl p-6',
        {
          'from-spotify to-spotify/60 text-white': isSelf,
          'from-gray-100 to-gray-50 dark:from-gray-600/10 dark:to-gray-800/40':
            !isSelf,
        }
      )}
    >
      <div
        className={classNames('flex justify-between w-full', {
          'text-spotify': !isSelf,
        })}
      >
        <svg
          className="w-10 h-10"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 168 168"
        >
          <path
            fill="currentColor"
            d="m83.996 0.277c-46.249 0-83.743 37.493-83.743 83.742 0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l0.001-0.004zm38.404 120.78c-1.5 2.46-4.72 3.24-7.18 1.73-19.662-12.01-44.414-14.73-73.564-8.07-2.809 0.64-5.609-1.12-6.249-3.93-0.643-2.81 1.11-5.61 3.926-6.25 31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-0.903-8.148-4.35-1.04-3.453 0.907-7.093 4.354-8.143 30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-0.001zm0.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219-1.254-4.14 1.08-8.513 5.221-9.771 29.581-8.98 78.756-7.245 109.83 11.202 3.73 2.209 4.95 7.016 2.74 10.733-2.2 3.722-7.02 4.949-10.73 2.739z"
          />
        </svg>
      </div>

      <div className="flex flex-col w-full mt-auto">
        <small
          className={classNames(
            'mb-0.5 text-sm font-black tracking-wider uppercase',
            {
              'text-white/60': isSelf,
              'text-secondary': !isSelf,
            }
          )}
        >
          Spotify
        </small>

        <p
          className={classNames(
            'text-xl font-black leading-tight break-words',
            { 'text-secondary': !isSelf }
          )}
        >
          {description}
        </p>

        {isSelf && (
          <Button
            onClick={connect}
            className="flex items-center justify-center w-full py-3 mt-4 text-sm font-semibold bg-white rounded-full text-spotify hover:bg-white/80"
          >
            Connect Spotify
          </Button>
        )}
      </div>
    </div>
  );
}
