import { ColorFamily, NewEventRsvpEnum } from '@graphql-types@';
import classNames from 'classnames';
import EventCard from 'components/EventPopover/EventCard';
import IconCaret from 'components/Icons/IconCaret';
import { useUserEmail } from 'contexts/auth';
import {
  useSetVisibleGuestCalendars,
  useVisibleCalendarIds,
} from 'hooks/useVisibleCalendars';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import { orderBy } from 'lodash';
import React, { useCallback } from 'react';
import { Attendee, IGridEvent } from 'types/events';
import { getInvitedAttendees, getPendingAttendees } from 'utils/attendees';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import EventGuestInput from './EventGuestInput';
import EventGuestList from './EventGuestList';
import ToggleCalendarsVisibilityButton from './ToggleCalendarsVisibilityButton';

interface Props {
  eventId: string;
  readOnly: boolean;
  value: IGridEvent['attendees'];
  colorFamily: ColorFamily;
  onChange: (value: IGridEvent['attendees']) => void;
}

const guestsCollapsedAtom = atomWithStorage('event-guestlist-collapsed', false);

function attendeeOrderBy(attendee: Attendee) {
  return [
    [
      NewEventRsvpEnum.Yes,
      NewEventRsvpEnum.No,
      NewEventRsvpEnum.NotInvited,
      NewEventRsvpEnum.Unknown,
    ].indexOf(attendee.RSVP),
    attendee.displayName || attendee.email,
  ];
}

export default function EventGuests(props: Props): React.ReactElement {
  const { readOnly, value: attendees, colorFamily, onChange } = props;
  const colorMap = EVENT_COLOR_MAP[colorFamily];
  const userEmail = useUserEmail();
  const hasSelfInAttendee = attendees.some(({ email }) => email === userEmail);
  const [expanded, setExpanded] = useAtom(guestsCollapsedAtom);
  const visibleCalendars = useVisibleCalendarIds();
  const visibleCalendarsLength = visibleCalendars.length;
  const totalGuests = attendees.length;

  const { removeGuestCalendar, addGuestCalendar } =
    useSetVisibleGuestCalendars();

  const addGuest = useCallback(
    (attendee: IGridEvent['attendees'][0]) => {
      if (attendees.find(({ email }) => email === attendee.email)) return;

      onChange([...attendees, attendee]);

      // only show calendar if display name is present,
      // i.e. do not attempt to show for group emails and external calendars
      if (!attendee.displayName) return;
      addGuestCalendar(attendee?.email);
    },
    [attendees, onChange, addGuestCalendar]
  );

  const deleteAttendee = useCallback(
    (attendeeId: string, attendeeEmail: string) => {
      if (readOnly) return;

      if (visibleCalendars.includes(attendeeEmail))
        removeGuestCalendar(attendeeEmail);

      if (attendeeId) {
        removeGuestCalendar(attendeeId);
      }

      onChange(attendees.filter(({ email }) => email !== attendeeEmail));
    },
    [readOnly, visibleCalendars, removeGuestCalendar, attendees, onChange]
  );

  const hasVisibleCalendars = visibleCalendarsLength > 1 && totalGuests > 1;

  const showCalendarsCopy = hasVisibleCalendars
    ? `Hide ${totalGuests === 1 ? 'calendar' : 'all calendars'}`
    : `Show ${totalGuests === 1 ? 'calendar' : ' all calendars'}`;

  return (
    <EventCard
      key="guests"
      title="Guests"
      colorFamily={colorFamily}
      readOnly={readOnly}
    >
      {({ hasFocus }) => (
        <>
          {!props.readOnly && (
            <EventGuestInput
              {...props}
              addGuest={addGuest}
              hasBorder={totalGuests > 0}
            />
          )}

          {totalGuests > 0 && (
            <div className="relative flex w-full flex-col gap-3 pt-2">
              <EventGuestList
                title="To be invited"
                orientation={expanded ? 'vertical' : 'horizontal'}
                items={orderBy(getPendingAttendees(attendees), attendeeOrderBy)}
                deleteAttendee={deleteAttendee}
                readOnly={readOnly}
                colorFamily={colorFamily}
                setExpanded={setExpanded}
              />

              <EventGuestList
                title="Invited"
                orientation={expanded ? 'vertical' : 'horizontal'}
                items={orderBy(getInvitedAttendees(attendees), attendeeOrderBy)}
                deleteAttendee={deleteAttendee}
                readOnly={readOnly}
                colorFamily={colorFamily}
                setExpanded={setExpanded}
              />

              <div className="absolute top-1 right-0 flex items-center">
                {!(totalGuests === 1 && hasSelfInAttendee) && (
                  <Tooltip
                    placement="bottom-center"
                    content={showCalendarsCopy}
                  >
                    <div className="flex">
                      <ToggleCalendarsVisibilityButton
                        attendees={attendees}
                        shouldShow={hasVisibleCalendars}
                        colorMap={colorMap}
                      />
                    </div>
                  </Tooltip>
                )}

                <Tooltip
                  content={expanded ? 'Collapse guests' : 'Show details'}
                >
                  <Button
                    variant="popoverIcon"
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      setExpanded((val) => !val);
                    }}
                    onMouseDown={(event: React.MouseEvent) =>
                      event.preventDefault()
                    }
                    className={classNames('shrink-0', {
                      [`${colorMap.text} ${colorMap.button} hover:!bg-opacity-100 focus:!bg-opacity-100`]:
                        hasFocus,
                    })}
                  >
                    <span
                      className={classNames('flex transition-transform', {
                        'rotate-180': expanded,
                      })}
                    >
                      <IconCaret />
                    </span>
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </>
      )}
    </EventCard>
  );
}
