import { ColorFamily } from '@graphql-types@';
import { useUpdateEffect } from '@react-aria/utils';
import { default as cn } from 'classnames';
import { AnimatePresence } from 'framer-motion';
import dynamic from 'next/dynamic';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  FocusEvent as ReactFocusEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';
import EventCard from './EventCard';
import styles from './EventDescription.module.css';

// This is enormous and includes Prosemirror library
const EventDescriptionEdit = dynamic(() => import('./EventDescriptionEdit'), {
  ssr: false,
});

interface EventDescriptionReadOnlyProps {
  value: string;
  readOnly?: boolean;
  forceUnfurl?: boolean;
  role?: string;
  onFocus?: (event: ReactFocusEvent) => void;
  onBlur?: (event: ReactFocusEvent) => void;
  onClick?: (event: ReactMouseEvent) => void;
}

export const EventDescriptionReadOnly: React.FC<EventDescriptionReadOnlyProps> =
  ({ value, readOnly, forceUnfurl, ...props }) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [readOnlyMarkup, setReadOnlyMarkup] = useState('');
    const [hasOverlay, setHasOverlay] = useState(false);
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const isCollapsed = useMemo(
      () => hasOverlay && !forceUnfurl,
      [hasOverlay, forceUnfurl]
    );

    // The only case when this element is not interactive,
    // is when it's both fully visible (has no overlay) and read only.
    const isInteractive = useMemo(
      () => !readOnly || hasOverlay,
      [readOnly, hasOverlay]
    );

    const onScroll = useCallback(() => {
      if (!contentRef?.current) return;

      setHasScrolledToBottom(
        contentRef.current.scrollTop + contentRef.current.offsetHeight >=
          contentRef.current.scrollHeight
      );
    }, []);

    useEffect(() => {
      if (forceUnfurl && contentRef?.current) {
        contentRef.current.focus();
      }
    }, [forceUnfurl]);

    useEffect(() => {
      const sanitize = async () => {
        const { default: sanitizeHtml } = await import('xss');

        const markup = sanitizeHtml(value || 'Write something interesting', {
          whiteList: {
            a: ['href', 'target', 'rel'],
            p: [],
            span: [],
            li: [],
            ul: [],
            ol: [],
            strong: [],
            div: [],
            em: [],
            s: [],
            u: [],
            b: [],
            i: [],
            mark: [],
            sub: [],
            sup: [],
          },
        });

        setReadOnlyMarkup(markup);

        if (!contentRef.current) return;
        const { height } = contentRef.current.getBoundingClientRect();
        setHasOverlay(height > 20);
      };

      sanitize();
    }, [value]);

    return (
      <div
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: readOnlyMarkup }}
        onScroll={onScroll}
        className={cn(
          'text-sm font-medium text-left overflow-y-auto overflow-x-hidden',
          styles.description,
          {
            'max-h-16': isCollapsed,
            'max-h-48': !isCollapsed,
            'fade-bg': hasOverlay && !hasScrolledToBottom,
            'text-gray-400': value === '',
            'cursor-default': !isInteractive,
          },
          'w-full'
        )}
        aria-disabled={!isInteractive}
        role="button"
        tabIndex={0}
        {...props}
      />
    );
  };

interface EventDescriptionProps {
  value: string;
  onChange: (value: string) => void;
  readOnly: boolean;
  hidden: boolean;
  colorFamily: ColorFamily;
}

const EventDescription: React.FC<EventDescriptionProps> = ({
  value,
  onChange,
  hidden,
  readOnly,
  colorFamily,
}) => {
  const [intentToEdit, setIntentToEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // This is very similar to intentToEdit, but separated to make it clearer
  // that it's for something else. When the description is readOnly,
  // we use this flag to tell EventDescriptionReadOnly that the
  // content should be unfurled.
  const [shouldUnfurlReadonlyText, setShouldUnfurlReadonlyText] =
    useState(false);

  const beginEditing = useCallback(() => {
    setIsEditing(true);
    setIntentToEdit(false);
  }, []);

  const endEditing = useCallback(() => {
    setIsEditing(false);
    setIntentToEdit(false);
  }, []);

  const triggerEdit = (event?: React.MouseEvent | React.FocusEvent) => {
    if (event?.target instanceof HTMLAnchorElement) return;

    if (readOnly) {
      setShouldUnfurlReadonlyText(true);
    } else {
      setIntentToEdit(true);
    }

    event?.stopPropagation();
  };

  const onBlur = useCallback(() => {
    setShouldUnfurlReadonlyText(false);
  }, [setShouldUnfurlReadonlyText]);

  useUpdateEffect(() => {
    if (!hidden) {
      triggerEdit();
    }
  }, [hidden]);

  // Click-to-edit
  return (
    <AnimatePresence>
      {!hidden && (
        <EventCard
          title="Description"
          colorFamily={colorFamily}
          readOnly={readOnly}
        >
          {(intentToEdit || isEditing) && (
            <EventDescriptionEdit
              value={value}
              onChange={onChange}
              beginEditing={beginEditing}
              endEditing={endEditing}
              readOnly={readOnly}
            />
          )}
          {!isEditing && (
            <EventDescriptionReadOnly
              value={value}
              readOnly={readOnly}
              onBlur={onBlur}
              forceUnfurl={shouldUnfurlReadonlyText}
              role="button"
              aria-label="Edit event description"
              onFocus={triggerEdit}
              onClick={triggerEdit}
            />
          )}
        </EventCard>
      )}
    </AnimatePresence>
  );
};

export default EventDescription;
