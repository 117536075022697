import { useVisibleEventsIds } from 'hooks/events/useGridEvents';
import { useCalendarDays } from 'hooks/useCalendar';
import React from 'react';
import { DraggableType } from 'types/drag-and-drop';
import GridEventWrapper from './GridEventWrapper';

export default React.memo(function GridContent(): JSX.Element {
  const eventsIds = useVisibleEventsIds();
  const days = useCalendarDays();

  return (
    <>
      {eventsIds.map((eventId, index) => (
        <GridEventWrapper
          key={`${index}-${eventId}`}
          allDay={false}
          days={days}
          eventId={eventId}
          type={DraggableType.EVENT}
        />
      ))}
    </>
  );
});
