import { useUpdateEffect } from '@react-aria/utils';
import useCalendar from 'hooks/useCalendar';
import useTimezone from 'hooks/useTimeZone';
import { useMountEffect } from 'joy/utils';
import { DateTime } from 'luxon';

interface GridScrollToCurrentTimeProps {
  scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  scrollElementRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default function GridScrollToCurrentTime(
  props: GridScrollToCurrentTimeProps
): null {
  const { startDate } = useCalendar();
  const { scrollContainerRef, scrollElementRef } = props;
  const timezone = useTimezone();

  useMountEffect(() => {
    focusGridCurrentTime({
      behavior: 'auto',
      timezone,
      scrollContainerRef,
      scrollElementRef,
    });
    const smoothScroll = () =>
      focusGridCurrentTime({
        behavior: 'smooth',
        timezone,
        scrollContainerRef,
        scrollElementRef,
      });
    document.addEventListener('grid:focus-current-time', smoothScroll);
    return () =>
      document.removeEventListener('grid:focus-current-time', smoothScroll);
  });

  useUpdateEffect(() => {
    focusGridCurrentTime({
      behavior: 'smooth',
      timezone,
      scrollContainerRef,
      scrollElementRef,
    });
  }, [startDate.ordinal, timezone]);

  return null;
}

export function gridFocusCurrentTime() {
  document.dispatchEvent(new CustomEvent('grid:focus-current-time'));
}

interface FocusGridCurrentTimeProps {
  scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  scrollElementRef: React.MutableRefObject<HTMLDivElement | null>;
  behavior: ScrollBehavior;
  timezone: string;
}

function focusGridCurrentTime({
  scrollContainerRef,
  scrollElementRef,
  behavior,
  timezone,
}: FocusGridCurrentTimeProps) {
  if (!scrollContainerRef.current?.scrollTo || !scrollElementRef.current) {
    return setTimeout(focusGridCurrentTime, 500);
  }
  const gridMeasure = scrollElementRef.current.getBoundingClientRect();
  const gridHeight = gridMeasure.height;
  // Focus the 4 hours before the current time
  const dayAdvancement =
    Math.max(0, DateTime.now().setZone(timezone).hour - 4) / 24;
  const offset = gridHeight * dayAdvancement;
  scrollContainerRef.current.scrollTo({
    top: offset,
    behavior,
  });
}
