import {
  CollisionDetection,
  rectIntersection,
  LayoutRect,
} from '@dnd-kit/core';
import { DroppableId } from 'types/drag-and-drop';

function maxX(r1: LayoutRect) {
  return r1.offsetLeft + r1.width;
}

function maxY(r1: LayoutRect) {
  return r1.offsetTop + r1.height;
}

function doRectsIntersects(r1: LayoutRect, r2: LayoutRect) {
  return !(
    r2.offsetLeft >= maxX(r1) ||
    maxX(r2) <= r1.offsetLeft ||
    r2.offsetTop >= maxY(r1) ||
    maxY(r2) <= r1.offsetTop
  );
}

export const customCollisionDetectionStrategy: CollisionDetection = (data) => {
  const { droppableContainers: rects, collisionRect } = data;
  const scheduleContainer = rects.find(
    (rect) => rect.id === DroppableId.SCHEDULE
  );
  const scheduleRect = scheduleContainer?.rect.current;

  if (!scheduleContainer || !scheduleRect) return rectIntersection(data);

  if (doRectsIntersects(collisionRect, scheduleRect))
    return DroppableId.SCHEDULE;

  return rectIntersection(data);
};
