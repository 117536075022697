import {
  ColorFamily,
  NewEventAttendeeResponseStatusEnum,
  NewEventRsvpEnum,
} from '@graphql-types@';
import classNames from 'classnames';
import { useUserEmail } from 'contexts/auth';
import { useEventCardFocusContext } from 'contexts/eventCardFocus';
import useContactSearch from 'hooks/useContactSearch';
import ComboBox, { ComboBoxActions, ComboBoxItem } from 'joy/ComboBox';
import Input from 'joy/Input';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useThrottle } from 'react-use';
import { IGridEvent } from 'types/events';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { EVENT_PLACEHOLDER_ADD_A_GUEST, getEmailDomain } from 'utils/events';
import { isEmail } from 'utils/parsing';

const SEARCH_CONTACTS_RESULT_LIMIT = 8;

interface Props {
  value: IGridEvent['attendees'];
  colorFamily: ColorFamily;
  hasBorder: boolean;
  addGuest: (value: IGridEvent['attendees'][0]) => void;
}

export default function EventGuestInput({
  hasBorder,
  value,
  colorFamily,
  addGuest,
}: Props) {
  const [hasInputError, setHasInputError] = useState(false);
  const userEmail = useUserEmail();
  const [inputValue, setInputValue] = useState<string>('');
  const term = useThrottle(inputValue, 25);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { hasFocus } = useEventCardFocusContext();
  const colorMap = EVENT_COLOR_MAP[colorFamily];

  const toExclude = useMemo(() => {
    if (!userEmail) return undefined;
    return [userEmail, ...value.map((attendee) => attendee.email)];
  }, [userEmail, value]);

  const { results } = useContactSearch({
    term,
    limit: SEARCH_CONTACTS_RESULT_LIMIT,
    exclude: toExclude,
  });

  const getAutoCompleteItems = (): ComboBoxItem[] => [
    { type: 'title', value: 'Contacts' },
    ...results.map((contact) => ({
      type: 'option' as const,
      avatar: contact.avatar,
      value: contact.emailAddress,
      text: contact.displayName || contact.emailAddress,
      subtext: contact.displayName
        ? getEmailDomain(contact.emailAddress)
        : undefined,
    })),
  ];

  const addGuestFromInput = useCallback(
    async (input = inputValue) => {
      if (!isEmail(input)) return setHasInputError(true);

      const result = results.find((r) => r.emailAddress === input);
      const email = result?.emailAddress || input;

      addGuest({
        id: input,
        email,
        RSVP: NewEventRsvpEnum.Unknown,
        responseStatus: NewEventAttendeeResponseStatusEnum.NeedsAction,
        organizer: false,
        displayName: result?.displayName,
        status: 'pending',
      });

      setInputValue('');
    },
    [addGuest, inputValue, results]
  );

  const onItemSelect = useCallback(
    async (_, item: ComboBoxItem | undefined, actions: ComboBoxActions) => {
      if (!item || item.type !== 'option') return;

      const result = results.find((r) => r.emailAddress === item.value);
      const email = result?.emailAddress || item.value;

      addGuestFromInput(email);

      actions.setInputValue('');
      actions.setOpen(false);
    },
    [addGuestFromInput, results]
  );

  return (
    <div
      className={classNames({
        'border-b pb-2': hasBorder,
        [`${colorMap.border} border-opacity-20`]: hasFocus,
        'border-gray-200/50 dark:border-gray-500/50': !hasFocus,
      })}
    >
      <ComboBox
        className="flex w-full flex-grow"
        items={getAutoCompleteItems}
        onSubmit={onItemSelect}
        onInputSubmit={addGuestFromInput}
        onInputChange={setInputValue}
        inputRef={inputRef}
        hideWhenEmpty={true}
        offset={12}
      >
        <Input
          ref={inputRef}
          placeholder={EVENT_PLACEHOLDER_ADD_A_GUEST}
          value={inputValue}
          spellCheck={false}
          onAnimationEnd={() => setHasInputError(false)}
          className={classNames(
            'w-full truncate bg-transparent text-sm font-medium outline-none transition-colors',
            {
              [colorMap.placeholder]: hasFocus,
              'animate-shake text-red-500 dark:text-red-400': hasInputError,
            }
          )}
        />
      </ComboBox>
    </div>
  );
}
