import {
  DragCancelEvent,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  useDndContext,
  useDndMonitor,
} from '@dnd-kit/core';
import { NewEventRsvpEnum } from '@graphql-types@';
import { generateEventUUID } from 'hooks/events/helpers/eventsHelpers';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useSetEventsSelection } from 'hooks/useEventsSelection';
import { useUpdateModal } from 'hooks/useModal';
import usePrevious from 'hooks/usePrevious';
import { useEffect, useRef } from 'react';
import { DraggableType, DroppableId } from 'types/drag-and-drop';
import { ModalType } from 'types/modal';
import { getGridMouseCoordinates } from './clickHandler/GridInteractionsHandler';
import { useGetDatePoint } from './clickHandler/helpers/dragHelpers';

export function GridContactEventGhost() {
  const { active, over } = useDndContext();
  const {
    createDraftEvent,
    deleteDraftEvent,
    updateGridEvent,
    updateGridEventForInteractionOnly,
  } = useUpdateGridEvent();
  const { openModal } = useUpdateModal();
  const { selectEvent, clearEventsSelection } = useSetEventsSelection();
  const prevOver = usePrevious(over?.id);
  const getDatePoint = useGetDatePoint();
  const draftIdRef = useRef(generateEventUUID());

  useEffect(() => {
    if (active?.data.current?.type === DraggableType.CONTACT) {
      document.body.classList.add('cursor-[grabbing]');
    } else {
      document.body.classList.remove('cursor-[grabbing]');
    }
  }, [active]);

  useEffect(() => {
    if (prevOver !== DroppableId.SCHEDULE) return;

    // Handle case when dragging back to original position, off the schedule.
    // Only while dragging...
    if (prevOver && active && !over?.id) {
      deleteDraftEvent();
    }
  }, [over?.id, prevOver, deleteDraftEvent, active]);

  useDndMonitor({
    onDragOver: (dragEvent: DragOverEvent) => {
      if (dragEvent.over?.id !== DroppableId.SCHEDULE) return;

      if (dragEvent.active.data.current?.type !== DraggableType.CONTACT) return;

      const pointDate = getDatePoint(dragEvent, getGridMouseCoordinates());

      // Ignore new position if invalid or is allday
      if (!pointDate) return;

      if (dragEvent.active.data.current?.type === DraggableType.CONTACT) {
        draftIdRef.current = generateEventUUID();
        createDraftEvent({
          id: draftIdRef.current,
          startAt: pointDate,
          endAt: pointDate.plus({
            minutes: 30,
          }),
          attendees: [
            {
              ...dragEvent.active.data.current.contact,
              status: 'pending',
              RSVP: NewEventRsvpEnum.Unknown,
            },
          ],
        });
      }
    },
    onDragStart: (dragEvent: DragStartEvent) => {
      if (dragEvent.active.data.current?.type === DraggableType.CONTACT) {
        deleteDraftEvent();
        clearEventsSelection();
      }
    },
    onDragMove: (dragEvent: DragMoveEvent) => {
      if (dragEvent.over?.id !== DroppableId.SCHEDULE) return;
      if (dragEvent.active.data.current?.type !== DraggableType.CONTACT) return;

      const pointDate = getDatePoint(dragEvent, getGridMouseCoordinates());

      // Ignore new position if invalid or is allday
      if (!pointDate) return;

      if (dragEvent.active.data.current?.type === DraggableType.CONTACT) {
        updateGridEventForInteractionOnly({
          id: draftIdRef.current,
          startAt: pointDate,
          endAt: pointDate.plus({
            minutes: 30,
          }),
        });
      }
    },
    onDragCancel: (event: DragCancelEvent) => {
      if (event.active.data.current?.type === DraggableType.CONTACT) {
        deleteDraftEvent();
      }
    },
    onDragEnd: async (event: DragEndEvent) => {
      if (event.active.data.current?.type === DraggableType.CONTACT) {
        await updateGridEvent({
          id: draftIdRef.current,
          attendees: [
            {
              ...event.active.data.current.contact,
              status: 'pending',
              RSVP: NewEventRsvpEnum.Unknown,
            },
          ],
        });
        selectEvent(draftIdRef.current);
        openModal(ModalType.Event);
      }
    },
  });

  return null;
}
