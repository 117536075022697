import { NewEventVisibilityEnum } from '@graphql-types@';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { IGridEvent } from '../../types/events';
import ContextMenu, {
  ContextMenuType,
  useUpdateContextMenu,
} from 'joy/ContextMenu';
import { DropdownItem } from 'joy/Dropdown';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useGetFullEventsSelection } from 'hooks/useEventsSelection';
import { isDraftEvent } from './utils';

export default function GridEventContextMenu({
  onClose,
  ctxMenuItems,
}: PropsWithChildren<{
  event: IGridEvent;
  onClose: () => void;
  ctxMenuItems: DropdownItem[];
}>) {
  return (
    <ContextMenu
      items={ctxMenuItems}
      onClose={onClose}
      placement={'right-start'}
    >
      <div className="absolute top-0 left-0 z-10 h-full w-full" />
    </ContextMenu>
  );
}

export function useGridEventContextMenu(event?: IGridEvent | null) {
  const { openContextMenu, closeContextMenu } = useUpdateContextMenu();
  const { updateGridEvent, saveGridEvent, deleteGridEvent } =
    useUpdateGridEvent();
  const getFullEventsSelection = useGetFullEventsSelection();
  const [ctxMenuItems, setCtxMenuItems] = useState<DropdownItem[]>([]);

  const onContextMenuClose = useCallback(() => {
    closeContextMenu(ContextMenuType.Event);
  }, [closeContextMenu]);

  const setItems = useCallback(async () => {
    const fullEventsSelection = await getFullEventsSelection();

    const allEventsPrivate = [...fullEventsSelection, event].every(
      (e) => e?.visibility == NewEventVisibilityEnum.Private
    );

    const allEventsDone = [...fullEventsSelection, event].every(
      (e) => !!e?.doneAt
    );

    const ownAllEvents = [...fullEventsSelection, event].every(
      (e) => e?.isOwnEvent
    );

    const belongToAllEvents = [...fullEventsSelection, event].every(
      (e) => e?.belongsToUserCalendar
    );

    const toggleCheck = () => {
      const saveOrUpdate = isDraftEvent(event)
        ? updateGridEvent
        : saveGridEvent;

      const doneAt = allEventsDone ? null : new Date().toISOString();

      if (fullEventsSelection.length > 1) {
        fullEventsSelection.forEach((selectedEvent) => {
          saveOrUpdate({
            id: selectedEvent.id,
            doneAt,
          });
        });
      } else if (event) {
        saveOrUpdate({
          id: event.id,
          doneAt,
        });
      }
    };

    const togglePrivate = () => {
      const saveOrUpdate = isDraftEvent(event)
        ? updateGridEvent
        : saveGridEvent;

      const visibility = allEventsPrivate
        ? NewEventVisibilityEnum.Public
        : NewEventVisibilityEnum.Private;

      if (fullEventsSelection.length > 1) {
        fullEventsSelection.forEach((selectedEvent) => {
          saveOrUpdate({
            id: selectedEvent.id,
            visibility,
          });
        });
      } else if (event) {
        saveOrUpdate({
          id: event.id,
          visibility,
        });
      }
    };

    const deleteEvents = async () => {
      if (fullEventsSelection.length > 1) {
        fullEventsSelection.forEach((selectedEvent) =>
          deleteGridEvent({
            eventId: selectedEvent.id,
          })
        );
      } else if (event) {
        deleteGridEvent({
          eventId: event.id,
        });
      }
    };

    const items: DropdownItem[] = [
      {
        type: 'option',
        value: allEventsDone ? 'Mark as undone' : 'Mark as done',
        disabled: !belongToAllEvents,
        onSelect: toggleCheck,
      },
      {
        type: 'option',
        disabled: !ownAllEvents,
        value: allEventsPrivate ? 'Make public' : 'Make private',
        onSelect: togglePrivate,
      },

      {
        type: 'option',
        value: 'Delete',
        variant: 'red',
        disabled: !ownAllEvents,
        onSelect: deleteEvents,
      },
    ];
    setCtxMenuItems(items);
  }, [
    getFullEventsSelection,
    event,
    deleteGridEvent,
    updateGridEvent,
    saveGridEvent,
  ]);

  const onContextMenuOpen = useCallback(() => {
    openContextMenu(ContextMenuType.Event);
    setItems();
  }, [openContextMenu, setItems]);

  return {
    onContextMenuClose,
    onContextMenuOpen,
    ctxMenuItems,
  };
}
