import {
  ColorFamily,
  NewEventVisibilityEnum,
  NewVideoConferenceProvider,
} from '@graphql-types@';
import { useUpdateEffect } from '@react-aria/utils';
import EventGuests from 'components/EventPopover/EventGuests/EventGuests';
import { isDraftEvent, isMultiDay } from 'components/Grid/utils';
import { useCalendarColor } from 'components/Panels/useAvailableCalendars';
import { userEmailAtom } from 'contexts/auth';
import {
  useGridEvent,
  useSyncVisibleCalendarEvents,
} from 'hooks/events/useGridEvents';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { preferencesAtom } from 'hooks/preferences/preferencesAtoms';
import { useUpdateCalendar } from 'hooks/useCalendar';
import useTimezone from 'hooks/useTimeZone';
import { useAtomCallback } from 'jotai/utils';
import { DateTime } from 'luxon';
import React, { useCallback, useRef, useState } from 'react';
import { IGridEvent } from 'types/events';
import { PRIVATE_EVENT_PLACEHOLDER_TITLE } from 'utils/events';
import { getDefaultVideoLocation } from 'utils/format';
import { isSameWeek } from 'utils/time';
import {
  AROUND_URL_REGEX,
  MEET_LINK_PLACEHOLDER,
  MEET_LINK_PLACEHOLDER_DRAFT,
  ZOOM_LINK_PLACEHOLDER,
} from 'utils/video';
import ColorPicker from '../ColorPicker';
import EventDate from './DateTimePicker/EventDateTimePicker';
import EventAllDayToggle from './EventAllDayToggle';
import EventDescription from './EventDescription';
import EventDone from './EventDone';
import EventLocation from './EventLocation';
import useLocationAutoFiller from './EventLocation/useLocationAutoFiller';
import EventOptions from './EventOptions';
import EventPrivacyMask from './EventPrivacyMask';
import EventRecurrence from './EventRecurrence';
import EventRsvp from './EventRsvp';
import { EventTitle } from './EventTitle';
import EventVisibility from './EventVisibility';

interface Props {
  eventId: string;
}

export default function PopoverContent({ eventId }: Props): JSX.Element | null {
  const popoverContentRef = useRef<HTMLFormElement | null>(null);
  const event = useGridEvent(eventId);
  const { updateGridEvent, saveGridEvent, deleteDraftEvent } =
    useUpdateGridEvent();
  const { setPreviewDate, setStartDate } = useUpdateCalendar();
  const refreshVisibleCalendarEvents = useSyncVisibleCalendarEvents();
  const timezone = useTimezone();
  const defaultEventColor = useCalendarColor(event?.calendarId || '');

  const activityTimer = useRef(0);
  const [touchedLocation, setTouchedLocation] = useState(false);
  const [addedLocationWithGuest, setAddedLocationWithGuest] = useState(false);

  const [titleGroupFocused, setTitleGroupFocused] = useState(false);
  const [visibleDescription, setVisibleDescription] = useState(
    event?.description != null && event.description !== ''
  );
  const isPrivateEvent =
    event?.belongsToUserCalendar === false &&
    event?.visibility === NewEventVisibilityEnum.Private;

  const readOnly = !event || !event.canEdit || isPrivateEvent;

  const setEventValue = useCallback(
    <KeyName extends keyof IGridEvent>(keyName: KeyName) =>
      (value: IGridEvent[KeyName] | undefined) => {
        updateGridEvent({ id: eventId, [keyName]: value }).then(() => {
          /**
           * If a recurrence rule is updated, it affects other events
           * in display, so refetch them.
           */
          if (keyName === 'recurrenceRules') {
            refreshVisibleCalendarEvents(event?.calendarId || '');
          }
        });
      },
    [eventId, updateGridEvent, refreshVisibleCalendarEvents, event?.calendarId]
  );

  const handleToggleIsAllDay = useCallback(
    (isAllDay) => {
      if (!event) return;
      if (isAllDay) {
        updateGridEvent({ id: eventId, isAllDay: true });
        return;
      }
      // Prevent non all day events from taking more than one day
      let startAt = event.prevStartAt || event.startAt;
      let endAt = event.prevEndAt || event.endAt;

      endAt = startAt.set({
        hour: endAt.hour,
        minute: endAt.minute,
      });

      if (startAt > endAt) {
        [startAt, endAt] = [endAt, startAt];
      }

      const nextSlot = DateTime.now().setZone(timezone).plus({ minutes: 15 });

      // Round to the nearest 15 minutes.
      nextSlot.set({ minute: Math.round(nextSlot.minute / 15) * 15 });

      // When turning 24h event into non 24h event it should remain on the date it was set on
      startAt = event.startAt.set({
        hour: nextSlot.hour,
        minute: nextSlot.minute,
      });

      endAt = startAt.plus({
        minutes: 30,
      });

      updateGridEvent({
        id: eventId,
        isAllDay: false,
        startAt,
        endAt,
      });
    },
    [event, eventId, updateGridEvent, timezone]
  );

  useUpdateEffect(() => {
    if (!event || isDraftEvent(event)) return;

    activityTimer.current = window.setTimeout(() => {
      // Autosave after period of inactivity
      saveGridEvent({ id: event.id, forceSave: true });
    }, 3000);
    return () => {
      window.clearTimeout(activityTimer.current);
    };
  }, []);

  const setLocationAndProvider = useCallback(
    (value) => {
      // Around links are special since they're added client-side...
      if (value && AROUND_URL_REGEX.test(value)) {
        setEventValue('videoConferences')([
          {
            link: value,
            provider: NewVideoConferenceProvider.Around,
          },
        ]);

        setEventValue('location')(value);
        return;
      }

      switch (value) {
        case 'settings':
          break;

        case MEET_LINK_PLACEHOLDER_DRAFT:
          setEventValue('videoConferences')([
            {
              link: value,
              provider: NewVideoConferenceProvider.GoogleMeet,
            },
          ]);
          setEventValue('location')(value);
          break;
        case MEET_LINK_PLACEHOLDER:
          setEventValue('videoConferences')([
            {
              link: value,
              provider: NewVideoConferenceProvider.GoogleMeet,
            },
          ]);
          setEventValue('location')(value);
          break;
        case ZOOM_LINK_PLACEHOLDER:
          setEventValue('videoConferences')([
            {
              link: value,
              provider: NewVideoConferenceProvider.Zoom,
            },
          ]);
          setEventValue('location')(value);
          break;
        default:
          setEventValue('videoConferences')([]);
          setEventValue('location')(value || '');
          break;
      }
    },
    [setEventValue]
  );

  const handleChangeGuests = useAtomCallback(
    useCallback(
      (get, _set, attendees: IGridEvent['attendees']) => {
        const userEmail = get(userEmailAtom);
        const previousAttendeesLength =
          event?.attendees?.filter((attendee) => attendee.email !== userEmail)
            .length || 0;
        const nextAttendeeLength = attendees.filter(
          (attendee) => attendee.email !== userEmail
        ).length;

        // If we're adding a new attendee, let's add a video link
        if (
          previousAttendeesLength === 0 &&
          nextAttendeeLength === 1 &&
          !event?.location &&
          !event?.videoConferences?.length
        ) {
          const userPreferences = get(preferencesAtom);
          const location = getDefaultVideoLocation(userPreferences);
          if (location) {
            setAddedLocationWithGuest(true);
            setLocationAndProvider(location);
          }
        }

        if (nextAttendeeLength === 0 && addedLocationWithGuest) {
          // If we're removing the new attendee we just added, remove the video link
          if (location || event?.videoConferences) {
            setAddedLocationWithGuest(false);
            setLocationAndProvider('');
          }
        }

        setEventValue('attendees')(attendees);
      },
      [
        addedLocationWithGuest,
        event?.attendees,
        event?.location,
        event?.videoConferences,
        setEventValue,
        setLocationAndProvider,
      ]
    )
  );

  const handleChangeLocation = useCallback(
    (value: string | undefined) => {
      setTouchedLocation(true);

      if (value === MEET_LINK_PLACEHOLDER && eventId === 'draft') {
        setLocationAndProvider(MEET_LINK_PLACEHOLDER_DRAFT);
      } else {
        setLocationAndProvider(value);
      }
    },
    [eventId, setLocationAndProvider]
  );

  if (!event) return null;

  // Location
  const eventLocation = event.videoConferences[0]?.link || event.location || '';
  const showLocationCard = !(readOnly && !eventLocation);

  const colorFamily: ColorFamily = event.colorFamily || defaultEventColor;

  return (
    <form
      className="flex w-full flex-col"
      ref={popoverContentRef}
      onSubmit={(event) => event.preventDefault()}
    >
      <div className="flex h-full w-full flex-col overflow-y-scroll p-2">
        <div className="mx-1 mt-1 mb-3 flex">
          {event?.belongsToUserCalendar && (
            <EventDone
              value={event.doneAt}
              colorFamily={colorFamily}
              titleGroupFocused={titleGroupFocused}
              onChange={setEventValue('doneAt')}
            />
          )}

          <EventTitle
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={(!event.title || isDraftEvent(event)) && !isPrivateEvent}
            eventId={event.id}
            colorFamily={colorFamily}
            readOnly={readOnly}
            value={
              isPrivateEvent ? PRIVATE_EVENT_PLACEHOLDER_TITLE : event.title
            }
            onPressEscape={deleteDraftEvent}
            titleGroupFocused={titleGroupFocused}
            setTitleGroupFocused={setTitleGroupFocused}
            onChange={setEventValue('title')}
          />
        </div>

        <div className="flex flex-col space-y-1">
          {!isPrivateEvent && showLocationCard && (
            <>
              <EventLocation
                colorFamily={colorFamily}
                readOnly={readOnly}
                value={eventLocation}
                onChange={handleChangeLocation}
                eventId={event.id}
                isDraft={event.isDraft}
              />
              <LocationAutofill
                readOnly={readOnly}
                event={event}
                touched={touchedLocation}
                onChange={setEventValue('location')}
              />
            </>
          )}
          {isPrivateEvent && (
            <EventPrivacyMask
              eventCalendarId={event?.calendarId}
              colorFamily={colorFamily}
            />
          )}
          {!isPrivateEvent && (
            <EventGuests
              eventId={event.id}
              colorFamily={colorFamily}
              readOnly={readOnly}
              value={event.attendees}
              onChange={handleChangeGuests}
            />
          )}

          <EventDate
            colorFamily={colorFamily}
            isAllDay={event.isAllDay || isMultiDay(event)}
            startAt={event.startAt}
            endAt={event.endAt}
            onChangeStart={(date: DateTime) => {
              setEventValue('startAt')(date);
              if (!isSameWeek(date, event.startAt)) {
                setPreviewDate(date);
                setStartDate(date);
              }
            }}
            onChangeEnd={(date: DateTime) => {
              setEventValue('endAt')(date);
              if (!isSameWeek(date, event.endAt)) {
                setPreviewDate(date);
                setStartDate(date);
              }
            }}
            readOnly={readOnly}
          />

          {!isPrivateEvent && (
            <EventDescription
              colorFamily={colorFamily}
              hidden={!visibleDescription}
              readOnly={readOnly}
              value={event.description || ''}
              onChange={setEventValue('description')}
            />
          )}
        </div>

        {!isPrivateEvent && (
          <div className="mt-2 flex h-6 items-center justify-between px-1.5 py-3.5">
            <div className="flex items-center space-x-1">
              <span className="mr-1">
                <ColorPicker
                  value={colorFamily}
                  onChange={setEventValue('colorFamily')}
                  disabled={!event.belongsToUserCalendar}
                />
              </span>
              <EventVisibility
                value={event.visibility}
                onChange={setEventValue('visibility')}
                readOnly={readOnly}
              />
              {event && (
                <EventRecurrence
                  event={event}
                  onChange={setEventValue('recurrenceRules')}
                />
              )}
              <EventAllDayToggle
                colorFamily={colorFamily}
                isAllDay={event.isAllDay}
                onChange={handleToggleIsAllDay}
                readOnly={readOnly}
              />
            </div>

            <div className="flex items-center space-x-2">
              {event && (
                <EventRsvp
                  value={event.rsvp}
                  onChange={setEventValue('rsvp')}
                  attendeeCount={event.attendees.length}
                />
              )}
              {!readOnly && (
                <EventOptions
                  event={event}
                  discardDraftEvent={deleteDraftEvent}
                  visibleDescription={visibleDescription}
                  setVisibleDescription={setVisibleDescription}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

function LocationAutofill(props: {
  event: IGridEvent;
  readOnly: boolean;
  touched: boolean;
  onChange: (value: string | undefined | null) => void;
}) {
  useLocationAutoFiller({
    location: props.event?.location,
    setLocation: props.onChange,
    dependencies: {
      attendees: props.event?.attendees || [],
    },
    options: {
      disabled: props.touched || props.readOnly || !!props.event?.location,
    },
  });
  return null;
}
